import { Box } from "@mui/material";
import "./Loader.css";

function Loader() {
  return (
    <div className="Loader">
      <Box sx={{ marginTop: "10vmin", alignItems: "center" }}>
        <header className="Loader-header">
          <img
            src="/assets/images/loader.svg"
            className="Loader-logo"
            alt="logo"
          />
        </header>
      </Box>
    </div>
  );
}

export default Loader;
