import { useAsyncFn } from "react-use";
import "./PrivacyPolicyRoute.css";
import Markdown from "react-markdown";
import Loader from "../components/Loader";
import { useEffect } from "react";
import { Box } from "@mui/material";

function PrivacyPolicyRoute() {
  const privacyPolicyUrl = "https://ireceipt.pro/docs/privacy_policy.md";
  const [state, doFetch] = useAsyncFn(async () => {
    const response = await fetch(privacyPolicyUrl);
    const result = await response.text();
    return result;
  }, [privacyPolicyUrl]);

  useEffect(() => {
    doFetch().catch((err) => {
      console.error(err);
    });
  }, [doFetch]);

  return !state.loading ? (
    <div className="PrivacyPolicyRoute">
      <Box margin={"12pt"}>
        <Markdown>{state.value || "File loading error"}</Markdown>
        <Box sx={{ marginBottom: "10vmin" }} />
      </Box>
    </div>
  ) : (
    <Loader />
  );
}

export default PrivacyPolicyRoute;
