import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./GalleryRoute.css";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { getDownloadURL, ref, StorageReference } from "firebase/storage";
import { PublicTemplatesModel } from "../models/database/PublicTemplates";
import { getDoc } from "firebase/firestore";
import { CacheDatastore } from "../services/CacheDatastore";
import FirebaseStorage from "../services/FirebaseStorage";
import { useNavigate } from "react-router-dom";

interface TemplateData {
  id: string;
  name: string;
  size: string;
  imageRef: StorageReference;
}

interface TemplateDataWithUrl extends TemplateData {
  imageUrl: string;
}

const templatesCacheDatastore = new CacheDatastore<TemplateDataWithUrl[]>();

const getTemplatesData = async (
  forceReload = false
): Promise<TemplateDataWithUrl[]> => {
  const cachedData = templatesCacheDatastore.get("templates");
  if (cachedData && !forceReload) return cachedData;
  const doc = await getDoc(PublicTemplatesModel.ref);
  const templates = doc.exists() ? doc.data() : new PublicTemplatesModel({});
  const pTemplates = Object.values(templates.map).map<TemplateData>(
    (template) => ({
      id: template.id,
      name: template.name,
      size: `${template.width}x${template.height}`,
      imageRef: ref(FirebaseStorage, `/public/images/${template.id}.png`),
    })
  );
  const pTemplatesWithUrl = await Promise.all(
    pTemplates.map<Promise<TemplateDataWithUrl>>(async (e) => ({
      ...e,
      imageUrl: await getDownloadURL(e.imageRef),
    }))
  );
  return templatesCacheDatastore.set("templates", pTemplatesWithUrl);
};

function GalleryRoute() {
  const defaultTemplatesState =
    templatesCacheDatastore.get("templates") ||
    templatesCacheDatastore.set("templates", []);
  const [showLoader, setShowLoader] = useState(!defaultTemplatesState.length);
  const navigate = useNavigate();
  const [publicTemplates, setPublicTemplates] = useState(defaultTemplatesState);
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );

  useEffect(() => {
    (async () => {
      const pTemplatesWithUrl = await getTemplatesData(true);
      setPublicTemplates(pTemplatesWithUrl);
      setShowLoader(false);
    })();
  }, []);

  const goToSandBox = (templateId: string): void => {
    if (templateId) {
      navigate(`/sandbox/public/${templateId}`);
    }
  };

  let cardContentSize = useMediaQuery("(min-width:450px)") ? 300 : 250;

  return !showLoader ? (
    <div className="GalleryRoute">
      <Grid container>
        {publicTemplates
          .sort((a, b) => {
            if (a.name === b.name) return 0;
            return a.name > b.name ? 1 : -1;
          })
          .map((template) => (
            <Grid
              key={template.id}
              sx={{ marginRight: "15px", marginTop: "15px" }}
            >
              <Card
                sx={{
                  display: "flex",
                  height: "212px",
                  width: `${cardContentSize + 150}px`,
                }}
                elevation={3}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent
                    sx={{
                      flex: "1 0 auto",
                      width: `${cardContentSize}px`,
                    }}
                  >
                    <Typography
                      component="div"
                      variant="h5"
                      noWrap={true}
                      sx={{ fontWeight: "bold" }}
                    >
                      {template.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      noWrap={true}
                      sx={{ color: "text.secondary" }}
                    >
                      ID: {template.id}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      noWrap={true}
                      sx={{ color: "text.secondary" }}
                    >
                      Preffered size: {template.size}
                    </Typography>
                    <Button
                      sx={{ marginTop: "50px" }}
                      variant="outlined"
                      onClick={() => goToSandBox(template.id)}
                    >
                      Sandbox
                    </Button>
                  </CardContent>
                </Box>
                <CardMedia
                  component="img"
                  sx={{
                    width: 150,
                    border: "1px solid #282c34",
                    borderRadius: "2%",
                  }}
                  image={template.imageUrl}
                  alt={template.name}
                />
              </Card>
            </Grid>
          ))}
      </Grid>
      <Box sx={{ marginBottom: "10vmin" }} />
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default GalleryRoute;
