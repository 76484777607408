import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import "./SandboxRoute.css";
import Loader from "../components/Loader";
import { User, sendEmailVerification } from "firebase/auth";
import { UserModel } from "../models/database";
import { useState, useEffect, useCallback } from "react";
import Mustache from "mustache";
import FirebaseStorage from "../services/FirebaseStorage";
import { ref, getBytes } from "firebase/storage";
import { getDoc } from "firebase/firestore";
import { CacheDatastore } from "../services/CacheDatastore";
import { PublicTemplatesModel } from "../models/database/PublicTemplates";
import { viewPorts } from "../models/database/viewports";
import ReplayIcon from "@mui/icons-material/Replay";
import { PrivateTemplatesModel } from "../models/database/User/PrivateTemplates";
import { PublicTemplate } from "../models/database/PublicTemplate";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { errorMessagesMap } from "../services/FirebaseAuth";

interface RequestSourcesFormats {
  pdf: string;
  jpg: string;
  png: string;
  webp: string;
}

interface RequestSources {
  curl: RequestSourcesFormats;
  browser: RequestSourcesFormats;
  node: RequestSourcesFormats;
  libTs: RequestSourcesFormats;
  libJs: RequestSourcesFormats;
}

const requestSourcesNames = {
  curl: "Curl",
  browser: "Browser",
  node: "Node JS",
  libTs: "@ireceipt.pro/js (TypeScript)",
  libJs: "@ireceipt.pro/js (JavaScript)",
} as const;

interface CacheData {
  name: string;
  box: {
    width: number;
    height: number;
  };
  template: string;
  args: string;
  isLoaded: boolean;
  isPublic: boolean;
}

const cacheDatastore = new CacheDatastore<CacheData>();
const templatesCacheDatastore = new CacheDatastore<{
  [key: string]: PublicTemplate;
}>();

const getTemplatesData = async (
  userTemplates: UserModel["templates"] = new PrivateTemplatesModel({}),
  forceReload = false
): Promise<{ [key: string]: PublicTemplate }> => {
  const cachedData = templatesCacheDatastore.get("templates");
  if (cachedData && Object.keys(cachedData || {}).length && !forceReload)
    return cachedData;
  const doc = await getDoc(PublicTemplatesModel.ref);
  const templates = doc.exists() ? doc.data() : new PublicTemplatesModel({});
  const allTEmplates: { [key: string]: PublicTemplate } = {};
  Object.keys(templates.map).forEach((k) => {
    allTEmplates[`public_${k}`] = templates.map[k];
  });
  Object.keys(userTemplates.map).forEach((k) => {
    allTEmplates[`private_${k}`] = userTemplates.map[k];
  });
  return templatesCacheDatastore.set("templates", allTEmplates);
};

const getTemplateData = async (
  templateId: string,
  templatesData: { [key: string]: PublicTemplate },
  forceReload: boolean = false
): Promise<CacheData | undefined> => {
  const cachedData = cacheDatastore.get(templateId);
  if (cachedData?.template?.length && !forceReload) return cachedData;
  const template = templatesData?.[templateId];
  if (!template) return;
  const htmlFileRef = ref(FirebaseStorage, `${template.ref}`);
  const htmlBuf = await getBytes(htmlFileRef);
  let decoder = new TextDecoder();
  const html = decoder.decode(htmlBuf);
  const jsonFileRef = ref(FirebaseStorage, `${template.argsRef}`);
  const jsonBuf = await getBytes(jsonFileRef);
  decoder = new TextDecoder();
  let str = decoder.decode(jsonBuf);
  try {
    const json = JSON.parse(str);
    if (json) str = JSON.stringify(json, undefined, "\t");
  } catch (err) {
    console.warn(err);
  }
  return cacheDatastore.set(templateId, {
    name: template.name,
    box: {
      width: template.width,
      height: template.height,
    },
    template: html,
    args: str,
    isLoaded: true,
    isPublic: true,
  });
};

const getTemplateIdFromPath = (location: Location): string | undefined => {
  const [, , templatePrefix, templatePostfix] = location.pathname.split("/");
  if (!templatePrefix || !templatePostfix) return;
  if (templatePrefix === ":type" || templatePostfix === ":id") return;
  const templateId = `${templatePrefix}_${templatePostfix}`;
  return templateId;
};

const setTemplateIdInPath = (
  location: Location,
  navigate: NavigateFunction,
  templateId: string
): void => {
  const [, routeBase] = location.pathname.split("/");
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  if (routeBase && type && id) {
    navigate(`/${routeBase}/${type}/${id}`);
  }
  return;
};

const requestSourceFormats = ["pdf", "jpg", "png", "webp"] as const;

const libraryMethodsMap = {
  public: {
    pdf: "createPdfFromPublicTemplate",
    jpg: "createJpgFromPublicTemplate",
    png: "createPngFromPublicTemplate",
    webp: "createWebpFromPublicTemplate",
  },
  private: {
    pdf: "createPdfFromPrivateTemplate",
    jpg: "createJpgFromPrivateTemplate",
    png: "createPngFromPrivateTemplate",
    webp: "createWebpFromPrivateTemplate",
  },
} as const;

const getCurlSource = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSourcesFormats => {
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  return requestSourceFormats.reduce<RequestSourcesFormats>((acc, val) => {
    acc[val] = `
curl -X 'POST' '${process.env.REACT_APP_API_URL}/v1/${val}/${type}/${id}' \\
-H 'Content-Type: application/json' \\
-H 'Authorization: Bearer YOUR-API-TOKEN' \\
-d '${JSON.stringify(
      {
        variables: parsedArgs,
        size: {
          width,
          height,
        },
      },
      undefined,
      "\t"
    )}'
`;
    return acc;
  }, {} as RequestSourcesFormats);
};

const getBrowserSource = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSourcesFormats => {
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  return requestSourceFormats.reduce<RequestSourcesFormats>((acc, val) => {
    acc[val] = `
fetch("${process.env.REACT_APP_API_URL}/v1/${val}/${type}/${id}", {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer YOUR-API-TOKEN'",
  },
  body: '${JSON.stringify(
    {
      variables: parsedArgs,
      size: {
        width,
        height,
      },
    },
    undefined,
    "\t"
  )}',
  method: "POST",
});
`;
    return acc;
  }, {} as RequestSourcesFormats);
};

const getNodeSource = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSourcesFormats => {
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  return requestSourceFormats.reduce<RequestSourcesFormats>((acc, val) => {
    acc[val] = `
fetch("${process.env.REACT_APP_API_URL}/v1/${val}/${type}/${id}", {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer YOUR-API-TOKEN'",
  },
  body: '${JSON.stringify(
    {
      variables: parsedArgs,
      size: {
        width,
        height,
      },
    },
    undefined,
    "\t"
  )}',
  method: "POST",
});
`;
    return acc;
  }, {} as RequestSourcesFormats);
};

const getLibJsSource = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSourcesFormats => {
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  return requestSourceFormats.reduce<RequestSourcesFormats>((acc, val) => {
    let method =
      libraryMethodsMap[type as keyof typeof libraryMethodsMap]?.[val];
    if (method) {
      acc[val] = `
const { IReceiptPRO } = require("@ireceipt.pro/js");
const irp = new IReceiptPRO(YOUR_API_KEY);
const image = await irp.${method}(
  "${id}",
  ${JSON.stringify(parsedArgs, undefined, "\t")},
  ${JSON.stringify({ width, height }, undefined, "\t")},
);
`;
    } else {
      acc[val] = "Error";
    }
    return acc;
  }, {} as RequestSourcesFormats);
};

const getLibTsSource = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSourcesFormats => {
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  return requestSourceFormats.reduce<RequestSourcesFormats>((acc, val) => {
    let method =
      libraryMethodsMap[type as keyof typeof libraryMethodsMap]?.[val];
    if (method) {
      acc[val] = `
import { IReceiptPRO } from '@ireceipt.pro/js';
const irp = new IReceiptPRO(YOUR_API_KEY);
const image = await irp.${method}(
  "${id}",
  ${JSON.stringify(parsedArgs, undefined, "\t")},
  ${JSON.stringify({ width, height }, undefined, "\t")},
);
`;
    } else {
      acc[val] = "Error";
    }
    return acc;
  }, {} as RequestSourcesFormats);
};

const getSources = (
  templateId: string,
  parsedArgs: { [key: string]: unknown },
  { width = 796, height = 1126 }
): RequestSources => {
  const curlSource = getCurlSource(templateId, parsedArgs, {
    width,
    height,
  });
  const browserSource = getBrowserSource(templateId, parsedArgs, {
    width,
    height,
  });
  const nodeSource = getNodeSource(templateId, parsedArgs, {
    width,
    height,
  });
  const libJsSource = getLibJsSource(templateId, parsedArgs, {
    width,
    height,
  });
  const libTsSource = getLibTsSource(templateId, parsedArgs, {
    width,
    height,
  });
  return {
    curl: curlSource,
    browser: browserSource,
    node: nodeSource,
    libJs: libJsSource,
    libTs: libTsSource,
  };
};

const getEditorMode = (requestSourceOption: keyof RequestSources): string => {
  switch (requestSourceOption) {
    case "browser":
    case "node":
    case "libJs":
      return "javascript";
    case "libTs":
      return "typescript";
    case "curl":
      return "sh";
    default:
      return "text";
  }
};

function SandboxRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [templateId, setTemplateId] = useState("");
  const [showRequestSourceDialog, setShowRequestSourceDialog] = useState<{
    templateId: string;
    isShown: boolean;
    sources?: RequestSources;
    current: keyof RequestSources;
    format: (typeof requestSourceFormats)[number];
  }>({
    templateId: "",
    sources: undefined,
    current: "curl",
    format: "pdf",
    isShown: false,
  });
  const defaultTemplatesState =
    templatesCacheDatastore.get("templates") ||
    templatesCacheDatastore.set("templates", {});
  const [isRunOperation, setRunOperation] = useState(false);
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );

  const [templatesData, setTemplatesDataRaw] = useState(defaultTemplatesState);
  const setTemplatesData = useCallback(
    (data: { [key: string]: PublicTemplate }) => {
      templatesCacheDatastore.set("templates", data);
      return setTemplatesDataRaw(data);
    },
    []
  );

  const defaultState = templateId
    ? cacheDatastore.get(templateId) ||
      cacheDatastore.set(templateId, {
        box:
          templatesData?.[templateId]?.width &&
          templatesData?.[templateId]?.height
            ? {
                width: templatesData[templateId].width,
                height: templatesData[templateId].height,
              }
            : {
                width: 796,
                height: 1126,
              },
        template: "",
        args: "{}",
        name: "",
        isLoaded: false,
        isPublic: true,
      })
    : {
        box: {
          width: 796,
          height: 1126,
        },
        template: "",
        args: "{}",
        name: "New Template",
        isLoaded: false,
        isPublic: true,
      };

  const [templateData, setTemplateDataRaw] = useState(defaultState);
  const setTemplateData = useCallback(
    (data: CacheData) => {
      cacheDatastore.set(templateId, data);
      setTemplateDataRaw(data);
    },
    [templateId]
  );

  useEffect(() => {
    (async () => {
      if (templateId !== "") {
        setShowMsg({
          type: "info",
          message: "Loading...",
          isShown: true,
        });
        const data = await getTemplateData(templateId, templatesData);
        setShowMsg({
          type: "info",
          message: "",
          isShown: false,
        });
        if (data) setTemplateData(data);
      }
    })();
  }, [templateId, templatesData, setTemplateData]);

  useEffect(() => {
    (async () => {
      if (!isLoadingUser) {
        const data = await getTemplatesData(user?.templates, true);
        setTemplatesData(data);
        if (templateId === "") {
          let firstTemplateId = getTemplateIdFromPath(location);
          if (firstTemplateId) {
            setTemplateId(firstTemplateId);
            return;
          }
          firstTemplateId = Object.entries(data || {}).sort((a, b) => {
            if (a[1].name === b[1].name) return 0;
            return a[1].name > b[1].name ? 1 : -1;
          })[0][0];
          if (firstTemplateId) {
            setTemplateIdInPath(location, navigate, firstTemplateId);
            setTemplateId(firstTemplateId);
            return;
          }
        }
      }
    })();
  }, [
    templateId,
    setTemplatesData,
    user?.templates,
    isLoadingUser,
    location,
    navigate,
  ]);

  const isValidJson = (s: string): { [key: string]: any } | undefined => {
    try {
      return JSON.parse(s);
    } catch (err) {
      console.warn(err);
      return undefined;
    }
  };

  const safeMustacheRender = (s: string, a: { [key: string]: any }): string => {
    try {
      return Mustache.render(s, a);
    } catch (err) {
      console.warn(err);
      return "<html></html>";
    }
  };

  const parsedArgs = isValidJson(templateData.args);

  const isContentLoaded = !isLoadingUser && templateData?.isLoaded;

  const isPrivateTemplate = /^private_/.test(templateId);

  const currentRequestSource =
    showRequestSourceDialog.sources?.[showRequestSourceDialog.current]?.[
      showRequestSourceDialog.format
    ] ?? "";

  useEffect(() => {
    if (
      !showRequestSourceDialog.isShown &&
      !showRequestSourceDialog.sources?.curl?.pdf
    )
      setShowRequestSourceDialog({
        templateId: templateId,
        sources: getSources(templateId, parsedArgs ?? {}, templateData.box),
        current: showRequestSourceDialog.current,
        isShown: showRequestSourceDialog.isShown,
        format: showRequestSourceDialog.format,
      });
  }, [parsedArgs, showRequestSourceDialog, templateData.box, templateId]);

  return isContentLoaded ? (
    <div className="SandboxRoute">
      {authUser?.emailVerified ? null : (
        <Typography textAlign={"center"} style={{ marginBottom: "5vmin" }}>
          {authUser
            ? "Confirm your email by clicking on the link from your email to see private templates."
            : "Log in or register to see private templates."}
          {authUser ? (
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                sendEmailVerification(authUser, {
                  url: `${window.location.origin}`,
                })
                  .then(() => {
                    setShowMsg({
                      type: "success",
                      message: `The letter has been sent to ${authUser.email}`,
                      isShown: true,
                    });
                  })
                  .catch((err) => {
                    if (errorMessagesMap[err.code]) {
                      setShowMsg({
                        type: "error",
                        message: `${errorMessagesMap[err.code]}`,
                        isShown: true,
                      });
                    } else {
                      setShowMsg({
                        type: "error",
                        message: `${err?.message}`,
                        isShown: true,
                      });
                    }
                  });
              }}
            >
              (resend confirmation email)
            </Typography>
          ) : null}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ marginTop: "12pt" }}>
          <FormControl sx={{ width: "50%", maxWidth: "400pt" }}>
            <InputLabel id="select-template-label">Template</InputLabel>
            <Select
              sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
              labelId="select-template-label"
              id="select-template-select"
              value={
                Object.keys(templatesData || {}).includes(templateId)
                  ? templateId
                  : ""
              }
              label={"Template"}
              onChange={(e) => {
                setTemplateIdInPath(location, navigate, e.target.value);
                setTemplateId(e.target.value);
              }}
            >
              {Object.entries(templatesData || {})
                .sort((a, b) => {
                  if (a[1].name === b[1].name) return 0;
                  return a[1].name > b[1].name ? 1 : -1;
                })
                .map(([k, v]) => (
                  <MenuItem
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    key={`menu_item_${k}`}
                    value={k}
                  >
                    {v.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            sx={{ marginLeft: "12pt", height: "42pt" }}
            variant={"outlined"}
            onClick={() => {
              setShowRequestSourceDialog({
                templateId,
                isShown: true,
                sources: getSources(
                  templateId,
                  parsedArgs ?? {},
                  templateData.box
                ),
                current: showRequestSourceDialog.current,
                format: showRequestSourceDialog.format,
              });
            }}
          >
            SHOW REQUEST <br />
            SOURCE
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Typography marginY={"auto"}>
              ID:{" "}
              <b>
                {templateId.replace(/^(private_|public_)/, "")} (
                {isPrivateTemplate ? "private" : "public"})
              </b>
            </Typography>
            <IconButton
              onClick={() => {
                setRunOperation(true);
                (async () => {
                  const dt = await getTemplatesData(user?.templates, true);
                  if (dt) setTemplatesData(dt);
                  const dt2 = await getTemplateData(templateId, dt, true);
                  if (dt2) setTemplateData(dt2);
                  if (templateId === "") {
                    let firstTemplateId = getTemplateIdFromPath(location);
                    if (firstTemplateId) {
                      setTemplateId(firstTemplateId);
                      return;
                    }
                    firstTemplateId = Object.entries(dt || {}).sort((a, b) => {
                      if (a[1].name === b[1].name) return 0;
                      return a[1].name > b[1].name ? 1 : -1;
                    })[0][0];
                    if (firstTemplateId) {
                      setTemplateIdInPath(location, navigate, firstTemplateId);
                      setTemplateId(firstTemplateId);
                      return;
                    }
                  }
                })()
                  .then(() => {
                    setShowMsg({
                      isShown: true,
                      type: "success",
                      message:
                        "The template data was downloaded from the server.",
                    });
                  })
                  .catch((err) => {
                    setShowMsg({
                      isShown: true,
                      type: "error",
                      message: `${err?.message}`,
                    });
                  })
                  .finally(() => {
                    setRunOperation(false);
                  });
              }}
              disabled={isRunOperation}
            >
              <ReplayIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid item xs={12}>
            <TextField
              sx={{ marginTop: "12pt" }}
              inputMode="numeric"
              id="box-size-width"
              label="Width"
              variant="outlined"
              value={templateData.box.width || ""}
              onChange={({ target }) => {
                try {
                  if (target.value === "") {
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        width: 0,
                      },
                    });
                    return;
                  }
                  const val = Number.parseInt(target.value);
                  if (Number.isFinite(val) && val !== templateData.box.width)
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        width: val,
                      },
                    });
                } catch (err) {
                  console.warn(err);
                }
              }}
            />
            <TextField
              sx={{ marginTop: "12pt" }}
              inputMode="numeric"
              id="box-size-height"
              label="Height"
              variant="outlined"
              value={templateData.box.height || ""}
              onChange={({ target }) => {
                try {
                  if (target.value === "") {
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        height: 0,
                      },
                    });
                    return;
                  }
                  const val = Number.parseInt(target.value);
                  if (Number.isFinite(val) && val !== templateData.box.height)
                    setTemplateData({
                      ...templateData,
                      box: {
                        ...templateData.box,
                        height: val,
                      },
                    });
                } catch (err) {
                  console.warn(err);
                }
              }}
            />
            <FormControl sx={{ width: "100pt", marginTop: "12pt" }}>
              <InputLabel id="select-format-label">Format</InputLabel>
              <Select
                sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                labelId="select-format-label"
                id="select-format-select"
                label={"Format"}
                value={
                  Object.keys(viewPorts).find(
                    (e) =>
                      viewPorts[e as keyof typeof viewPorts].width ===
                        templateData.box.width &&
                      viewPorts[e as keyof typeof viewPorts].height ===
                        templateData.box.height
                  ) || ""
                }
                onChange={(e) => {
                  if (e.target.value) {
                    const viewport =
                      viewPorts[e.target.value as keyof typeof viewPorts];
                    if (viewport)
                      setTemplateData({
                        ...templateData,
                        box: {
                          width: viewport.width,
                          height: viewport.height,
                        },
                      });
                  }
                }}
              >
                {Object.keys(viewPorts).map((k) => (
                  <MenuItem
                    sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    key={`menu_item_viewports_${k}`}
                    value={k}
                  >
                    {k.toUpperCase().replace(/-/gi, " ")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} marginTop={"12pt"}>
            <Paper variant="outlined">
              <InputLabel
                sx={{
                  marginX: "3pt",
                  marginY: "-9pt",
                  backgroundColor: "white",
                  paddingX: "3pt",
                  paddingY: "0pt",
                  width: "68pt",
                }}
              >
                Arguments
              </InputLabel>
              <AceEditor
                placeholder="{}"
                mode="json"
                theme="github"
                onChange={(val) => {
                  try {
                    setTemplateData({
                      ...templateData,
                      args: val,
                    });
                  } catch (err) {
                    console.warn(err);
                  }
                }}
                value={templateData.args}
                name="sndbx-template-arguments"
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: false,
                  showLineNumbers: false,
                  tabSize: 2,
                  useWorker: false,
                }}
                editorProps={{ $blockScrolling: true }}
                fontSize={14}
                showPrintMargin={true}
                showGutter={false}
                highlightActiveLine={true}
                style={{ marginTop: "12pt" }}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <InputLabel>Preview</InputLabel>
          <iframe
            id="template-frame"
            title="Template"
            style={{
              width: templateData.box.width,
              height: templateData.box.height,
              zoom: 0.75,
              transform: "scale(0.75)",
              transformOrigin: "0 0",
              overflow: "scroll",
              // marginTop: '12pt',
              marginBottom: "12pt",
            }}
            srcDoc={safeMustacheRender(templateData.template, parsedArgs || {})}
          ></iframe>
        </Grid>
        <Box sx={{ marginBottom: "10vmin" }} />
      </Grid>
      {showRequestSourceDialog.isShown ? (
        <Dialog open={true} fullScreen>
          <DialogTitle>Request Source Code</DialogTitle>
          <DialogContent sx={{ maxWidth: "90%", maxHeight: "80%" }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl sx={{ width: "200pt", marginTop: "12pt" }}>
                  <InputLabel id="select-current-source-lang-label">
                    Language
                  </InputLabel>
                  <Select
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    labelId="select-current-source-lang-label"
                    id="select-current-source-lang-select"
                    label={"Language"}
                    value={showRequestSourceDialog.current}
                    onChange={(e) => {
                      if (
                        Object.keys(requestSourcesNames).includes(
                          e.target.value
                        )
                      ) {
                        setShowRequestSourceDialog({
                          templateId: showRequestSourceDialog.templateId,
                          sources: showRequestSourceDialog.sources,
                          isShown: showRequestSourceDialog.isShown,
                          current: e.target.value as keyof RequestSources,
                          format: showRequestSourceDialog.format,
                        });
                      }
                    }}
                  >
                    {Object.keys(requestSourcesNames).map((k) => (
                      <MenuItem
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        key={`menu_item_source_lang_${k}`}
                        value={k}
                      >
                        {requestSourcesNames[
                          k as keyof typeof requestSourcesNames
                        ] ?? k.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  sx={{
                    width: "200pt",
                    marginTop: "12pt",
                    marginLeft: "12pt",
                  }}
                >
                  <InputLabel id="select-current-source-resp-format-label">
                    Format
                  </InputLabel>
                  <Select
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    labelId="select-current-source-resp-format-label"
                    id="select-current-source-resp-format-select"
                    label={"Format"}
                    value={showRequestSourceDialog.format}
                    onChange={(e) => {
                      if (
                        requestSourceFormats.includes(
                          e.target
                            .value as (typeof requestSourceFormats)[number]
                        )
                      ) {
                        setShowRequestSourceDialog({
                          templateId: showRequestSourceDialog.templateId,
                          sources: showRequestSourceDialog.sources,
                          isShown: showRequestSourceDialog.isShown,
                          current: showRequestSourceDialog.current,
                          format: e.target
                            .value as (typeof requestSourceFormats)[number],
                        });
                      }
                    }}
                  >
                    {requestSourceFormats.map((k) => (
                      <MenuItem
                        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        key={`menu_item_source_resp_format_${k}`}
                        value={k}
                      >
                        {k.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} marginTop={"12pt"} paddingRight={"12pt"}>
                <Paper variant="outlined">
                  <InputLabel
                    sx={{
                      marginX: "3pt",
                      marginY: "-9pt",
                      backgroundColor: "white",
                      paddingLeft: "3pt",
                      paddingRight: "3pt",
                      width: "46pt",
                    }}
                  >
                    Source
                  </InputLabel>
                  <AceEditor
                    placeholder={currentRequestSource}
                    mode={getEditorMode(showRequestSourceDialog.current)}
                    theme="github"
                    width="100%"
                    minLines={20}
                    maxLines={20}
                    onChange={(val) => {
                      try {
                        const newSources =
                          showRequestSourceDialog.sources ??
                          getSources(
                            showRequestSourceDialog.templateId,
                            parsedArgs || {},
                            templateData.box
                          );
                        newSources[showRequestSourceDialog.current][
                          showRequestSourceDialog.format
                        ] = val;
                        setShowRequestSourceDialog({
                          templateId: showRequestSourceDialog.templateId,
                          isShown: showRequestSourceDialog.isShown,
                          sources: newSources,
                          current: showRequestSourceDialog.current,
                          format: showRequestSourceDialog.format,
                        });
                      } catch (err) {
                        console.warn(err);
                      }
                    }}
                    value={currentRequestSource}
                    name="editor-request-source"
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 2,
                      useWorker: false,
                    }}
                    editorProps={{ $blockScrolling: true }}
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={false}
                    highlightActiveLine={true}
                    style={{ marginTop: "12pt" }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowRequestSourceDialog({
                  templateId: showRequestSourceDialog.templateId,
                  isShown: false,
                  sources: showRequestSourceDialog.sources,
                  current: showRequestSourceDialog.current,
                  format: showRequestSourceDialog.format,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await navigator.clipboard
                  .writeText(currentRequestSource ?? "")
                  .then(() => {
                    setShowMsg({
                      type: "success",
                      message: "Copied!",
                      isShown: true,
                    });
                  })
                  .catch((err: Error) => {
                    setShowMsg({
                      type: "error",
                      message: err.message || "Unknown error",
                      isShown: true,
                    });
                  });
              }}
            >
              Copy
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default SandboxRoute;
