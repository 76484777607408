import {
  useLocation,
  useNavigate,
  Location,
  NavigateFunction,
} from "react-router-dom";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Snackbar,
  Alert,
  Toolbar,
  Paper,
} from "@mui/material";
import "./TemplatesRoute.css";
import Loader from "../components/Loader";
import { User, sendEmailVerification } from "firebase/auth";
import { UserModel } from "../models/database";
import { useState, useEffect, useCallback } from "react";
import FirebaseStorage from "../services/FirebaseStorage";
import { ref, getBytes } from "firebase/storage";
import { getDoc } from "firebase/firestore";
import { CacheDatastore } from "../services/CacheDatastore";
import { PublicTemplatesModel } from "../models/database/PublicTemplates";
import TuneIcon from "@mui/icons-material/Tune";
import ReplayIcon from "@mui/icons-material/Replay";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import Mustache from "mustache";
import { viewPorts } from "../models/database/viewports";
import { useDeleteTemplate } from "../methods/deleteTemplate";
import { useUpsertTemplate } from "../methods/upsertTemplate";
import { useDeletePublicTemplate } from "../methods/deletePublicTemplate";
import { useUpsertPublicTemplate } from "../methods/upsertPublicTemplate";
import { PrivateTemplatesModel } from "../models/database/User/PrivateTemplates";
import { PublicTemplate } from "../models/database/PublicTemplate";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { randomStr } from "../utils/randomStr";
import { getErrorFromCallable } from "../utils/getErrorFromCallable";
import FirebaseAnalytics from "../services/FirebaseAnalytics";
import { logEvent } from "firebase/analytics";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";
import { errorMessagesMap } from "../services/FirebaseAuth";

interface CacheData {
  name: string;
  box: {
    width: number;
    height: number;
  };
  template: string;
  args: string;
  isLoaded: boolean;
  isPublic: boolean;
}
const cacheDatastore = new CacheDatastore<CacheData>();
const templatesCacheDatastore = new CacheDatastore<{
  [key: string]: PublicTemplate;
}>();

const getTemplatesData = async (
  userTemplates: UserModel["templates"] = new PrivateTemplatesModel({}),
  forceReload = false
): Promise<{ [key: string]: PublicTemplate }> => {
  const cachedData = templatesCacheDatastore.get("templates");
  if (cachedData && Object.keys(cachedData || {}).length && !forceReload)
    return cachedData;
  const doc = await getDoc(PublicTemplatesModel.ref);
  const templates = doc.exists() ? doc.data() : new PublicTemplatesModel({});
  const allTEmplates: { [key: string]: PublicTemplate } = {};
  Object.keys(templates.map).forEach((k) => {
    allTEmplates[`public_${k}`] = templates.map[k];
  });
  Object.keys(userTemplates.map).forEach((k) => {
    allTEmplates[`private_${k}`] = userTemplates.map[k];
  });
  return templatesCacheDatastore.set("templates", allTEmplates);
};

const getTemplateData = async (
  templateId: string,
  templatesData: { [key: string]: PublicTemplate },
  forceReload: boolean = false
): Promise<CacheData | undefined> => {
  const cachedData = cacheDatastore.get(templateId);
  if (cachedData?.template?.length && !forceReload) return cachedData;
  const template = templatesData?.[templateId];
  if (!template) return;
  const htmlFileRef = ref(FirebaseStorage, `${template.ref}`);
  const htmlBuf = await getBytes(htmlFileRef);
  let decoder = new TextDecoder();
  const html = decoder.decode(htmlBuf);
  const jsonFileRef = ref(FirebaseStorage, `${template.argsRef}`);
  const jsonBuf = await getBytes(jsonFileRef);
  decoder = new TextDecoder();
  let str = decoder.decode(jsonBuf);
  try {
    const json = JSON.parse(str);
    if (json) str = JSON.stringify(json, undefined, "\t");
  } catch (err) {
    console.warn(err);
  }
  return cacheDatastore.set(templateId, {
    name: template.name,
    box: {
      width: template.width,
      height: template.height,
    },
    template: html,
    args: str,
    isLoaded: true,
    isPublic: true,
  });
};

const getTemplateIdFromPath = (location: Location): string | undefined => {
  const [, , templatePrefix, templatePostfix] = location.pathname.split("/");
  if (!templatePrefix || !templatePostfix) return;
  if (templatePrefix === ":type" || templatePostfix === ":id") return;
  const templateId = `${templatePrefix}_${templatePostfix}`;
  return templateId;
};

const setTemplateIdInPath = (
  location: Location,
  navigate: NavigateFunction,
  templateId: string
): void => {
  const [, routeBase] = location.pathname.split("/");
  const [type, ...idArray] = templateId.split("_");
  const id = idArray.join("_");
  if (routeBase && type && id) {
    navigate(`/${routeBase}/${type}/${id}`);
  }
  return;
};

function TemplatesRoute({
  authUser,
  user,
  isLoadingUser,
}: {
  authUser: User | null | undefined;
  user: UserModel | undefined;
  isLoadingUser: boolean;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [templateId, setTemplateId] = useState("");
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isRunOperation, setRunOperation] = useState(false);
  const [showMsg, setShowMsg] = useState(
    {} as {
      type: "success" | "warning" | "info" | "error";
      message: string;
      isShown: boolean;
    }
  );
  const [dialogData, setDialogData] = useState<{
    templateName?: string;
    templateArgs?: string;
    boxWidth?: number;
    boxHeight?: number;
  }>({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(
    {} as {
      type: "upsert" | "delete";
      isShown: boolean;
    }
  );
  const defaultTemplatesState =
    templatesCacheDatastore.get("templates") ||
    templatesCacheDatastore.set("templates", {});

  const [templatesData, setTemplatesDataRaw] = useState(defaultTemplatesState);
  const setTemplatesData = useCallback(
    (data: { [key: string]: PublicTemplate }) => {
      templatesCacheDatastore.set("templates", data);
      return setTemplatesDataRaw(data);
    },
    []
  );

  const deleteTemplate = useDeleteTemplate();
  const upsertTemplate = useUpsertTemplate();
  const deletePublicTemplate = useDeletePublicTemplate();
  const upsertPublicTemplate = useUpsertPublicTemplate();

  const defaultState = templateId
    ? cacheDatastore.get(templateId) ||
      cacheDatastore.set(templateId, {
        name: templatesData?.[templateId]?.name,
        box:
          templatesData?.[templateId]?.width &&
          templatesData?.[templateId]?.height
            ? {
                width: templatesData[templateId].width,
                height: templatesData[templateId].height,
              }
            : {
                width: 796,
                height: 1126,
              },
        template: "",
        args: "{}",
        isLoaded: false,
        isPublic: true,
      })
    : {
        name: "New Template",
        box: {
          width: 796,
          height: 1126,
        },
        template: "",
        args: "{}",
        isLoaded: false,
        isPublic: true,
      };

  const [templateData, setTemplateDataRaw] = useState(defaultState);
  const setTemplateData = useCallback(
    (data: CacheData) => {
      cacheDatastore.set(templateId, data);
      setTemplateDataRaw(data);
    },
    [templateId]
  );

  useEffect(() => {
    (async () => {
      if (templateId !== "") {
        setShowMsg({
          type: "info",
          message: "Loading...",
          isShown: true,
        });
        const data = await getTemplateData(templateId, templatesData);
        if (data) setTemplateData(data);
        setShowMsg({
          type: "info",
          message: "",
          isShown: false,
        });
      }
    })();
  }, [templateId, templatesData, setTemplateData]);

  useEffect(() => {
    (async () => {
      if (!isLoadingUser) {
        const data = await getTemplatesData(user?.templates, true);
        setTemplatesData(data);
        if (templateId === "") {
          let firstTemplateId = getTemplateIdFromPath(location);
          if (firstTemplateId) {
            setTemplateId(firstTemplateId);
            return;
          }
          firstTemplateId = Object.entries(data || {}).sort((a, b) => {
            if (a[1].name === b[1].name) return 0;
            return a[1].name > b[1].name ? 1 : -1;
          })[0][0];
          if (firstTemplateId) {
            setTemplateIdInPath(location, navigate, firstTemplateId);
            setTemplateId(firstTemplateId);
            return;
          }
        }
      }
    })();
  }, [
    templateId,
    setTemplatesData,
    user?.templates,
    isLoadingUser,
    location,
    navigate,
  ]);

  useEffect(() => {
    (async () => {
      const token = await authUser?.getIdTokenResult();
      setIsAdmin(!!token?.claims.isAdmin);
    })();
  });

  const isValidJson = (s: string): { [key: string]: any } | undefined => {
    try {
      return JSON.parse(s);
    } catch (err) {
      console.warn(err);
      return undefined;
    }
  };

  const safeMustacheRender = (s: string, a: { [key: string]: any }): string => {
    try {
      return Mustache.render(s, a);
    } catch (err) {
      console.warn(err);
      return "<html></html>";
    }
  };

  const parsedArgs = isValidJson(templateData.args);

  const isContentLoaded = !isLoadingUser && templateData.isLoaded;

  const isInProgress =
    isRunOperation ||
    deleteTemplate[1] ||
    upsertTemplate[1] ||
    deletePublicTemplate[1] ||
    upsertPublicTemplate[1];

  const isPrivateTemplate = /^private_/.test(templateId);

  const canEditTemplate = isPrivateTemplate || isAdmin;

  const reloadTemplates = () => {
    setRunOperation(true);
    (async () => {
      const dt = await getTemplatesData(user?.templates, true);
      const dt2 = await getTemplateData(templateId, dt, true);
      if (dt2) setTemplateData(dt2);
      else if (dt) setTemplatesData(dt);
      if (templateId === "") {
        let firstTemplateId = getTemplateIdFromPath(location);
        if (firstTemplateId) {
          setTemplateId(firstTemplateId);
          return;
        }
        firstTemplateId = Object.entries(dt || {}).sort((a, b) => {
          if (a[1].name === b[1].name) return 0;
          return a[1].name > b[1].name ? 1 : -1;
        })[0][0];
        if (firstTemplateId) {
          setTemplateIdInPath(location, navigate, firstTemplateId);
          setTemplateId(firstTemplateId);
          return;
        }
      }
    })()
      .then(() => {
        setShowMsg({
          isShown: true,
          type: "success",
          message: "The template data was downloaded from the server.",
        });
      })
      .catch((err) => {
        setShowMsg({
          isShown: true,
          type: "error",
          message: `${err?.message}`,
        });
      })
      .finally(() => {
        setRunOperation(false);
      });
  };

  return isContentLoaded ? (
    <div className="TemplatesRoute">
      {authUser?.emailVerified ? null : (
        <Typography textAlign={"center"} style={{ marginBottom: "5vmin" }}>
          {authUser
            ? "Confirm your email by clicking on the link from your email to see or edit private templates."
            : "Log in or register to see or edit private templates."}
          {authUser ? (
            <Typography
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                sendEmailVerification(authUser, {
                  url: `${window.location.origin}`,
                })
                  .then(() => {
                    setShowMsg({
                      type: "success",
                      message: `The letter has been sent to ${authUser.email}`,
                      isShown: true,
                    });
                  })
                  .catch((err) => {
                    if (errorMessagesMap[err.code]) {
                      setShowMsg({
                        type: "error",
                        message: `${errorMessagesMap[err.code]}`,
                        isShown: true,
                      });
                    } else {
                      setShowMsg({
                        type: "error",
                        message: `${err?.message}`,
                        isShown: true,
                      });
                    }
                  });
              }}
            >
              (resend confirmation email)
            </Typography>
          ) : null}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ paddingLeft: "0px" }}>
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <FormControl
              sx={{
                width: "50%",
                maxWidth: "400pt",
              }}
            >
              <InputLabel id="select-template-label">Template</InputLabel>
              <Select
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                labelId="select-template-label"
                id="select-template-select"
                value={
                  Object.keys(templatesData || {}).includes(templateId)
                    ? templateId
                    : ""
                }
                label={"Template"}
                onChange={(e) => {
                  setTemplateIdInPath(location, navigate, e.target.value);
                  setTemplateId(e.target.value);
                }}
              >
                {Object.entries(templatesData || {})
                  .sort((a, b) => {
                    if (a[1].name === b[1].name) return 0;
                    return a[1].name > b[1].name ? 1 : -1;
                  })
                  .map(([k, v]) => (
                    <MenuItem
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      key={`menu_item_${k}`}
                      value={k}
                    >
                      {v.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {isAdmin ? (
              <Button
                sx={{ marginLeft: "auto", marginY: "auto" }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setTemplateId(`public_${randomStr(20)}`);
                }}
                disabled={!user || !authUser?.emailVerified || isInProgress}
              >
                <AddCircleOutlineOutlinedIcon color="info" />
              </Button>
            ) : null}
            <Button
              sx={{ marginLeft: isAdmin ? "12pt" : "auto", marginY: "auto" }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setTemplateId(`private_${randomStr(20)}`);
                return;
              }}
              disabled={!user || !authUser?.emailVerified || isInProgress}
            >
              <AddCircleOutlineOutlinedIcon />
            </Button>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Typography marginY={"auto"}>
              ID:{" "}
              <b>
                {templateId.replace(/^(private_|public_)/, "")} (
                {isPrivateTemplate ? "private" : "public"})
              </b>
            </Typography>
            <IconButton
              onClick={() => {
                setDialogData({
                  templateName: templateData.name,
                  templateArgs: templateData.args,
                  boxHeight: templateData.box.height,
                  boxWidth: templateData.box.width,
                });
                setOpenDialog(true);
              }}
              disabled={!canEditTemplate || isInProgress}
            >
              <TuneIcon />
            </IconButton>
            <IconButton onClick={reloadTemplates} disabled={isInProgress}>
              <ReplayIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setShowConfirmDialog({
                  type: "upsert",
                  isShown: true,
                });
              }}
              disabled={!canEditTemplate || isInProgress}
            >
              <SaveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setShowConfirmDialog({
                  type: "delete",
                  isShown: true,
                });
              }}
              disabled={!canEditTemplate || isInProgress}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12} md={7} marginBottom={"12pt"}>
          <Paper variant="outlined">
            <InputLabel
              sx={{
                marginX: "3pt",
                marginY: "-12pt",
                backgroundColor: "white",
                paddingX: "3pt",
                paddingY: "0pt",
                width: "72pt",
              }}
            >
              HTML Code
            </InputLabel>
            <AceEditor
              placeholder="<html><body></body></html>"
              mode="html"
              theme="github"
              onChange={(val) => {
                try {
                  setTemplateData({
                    ...templateData,
                    template: val,
                  });
                } catch (err) {
                  console.warn(err);
                }
              }}
              value={templateData.template}
              name="editor-template-code"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: false,
                tabSize: 2,
                useWorker: false,
              }}
              editorProps={{ $blockScrolling: true }}
              fontSize={14}
              showPrintMargin={true}
              showGutter={false}
              highlightActiveLine={true}
              style={{ marginTop: "12pt" }}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={5}>
          <InputLabel>Preview</InputLabel>
          <iframe
            id="template-frame"
            title="Template"
            style={{
              width: templateData.box.width,
              height: templateData.box.height,
              zoom: 0.75,
              transform: "scale(0.75)",
              transformOrigin: "0 0",
              overflow: "scroll",
              // marginTop: '12pt',
              marginBottom: "12pt",
            }}
            srcDoc={safeMustacheRender(templateData.template, parsedArgs || {})}
          ></iframe>
        </Grid>
        <Box sx={{ marginBottom: "10vmin" }} />
      </Grid>
      {isOpenDialog ? (
        <Dialog open={true} fullScreen>
          <DialogTitle>{templateId}</DialogTitle>
          <DialogContent sx={{ maxWidth: "100%" }}>
            <Grid container>
              {isAdmin ? (
                <Grid item xs={12}>
                  <TextField
                    sx={{ marginTop: "12pt" }}
                    inputMode="text"
                    id="template-id"
                    label="ID"
                    variant="outlined"
                    value={templateId}
                    onChange={({ target }) => {
                      try {
                        setTemplateIdInPath(location, navigate, target.value);
                        setTemplateId(target.value);
                      } catch (err) {
                        console.warn(err);
                      }
                    }}
                  />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <TextField
                  sx={{ marginTop: "12pt" }}
                  inputMode="text"
                  id="template-name"
                  label="Name"
                  variant="outlined"
                  value={dialogData.templateName}
                  onChange={({ target }) => {
                    try {
                      setDialogData({
                        ...dialogData,
                        templateName: target.value,
                      });
                    } catch (err) {
                      console.warn(err);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid item xs={12}>
                  <TextField
                    sx={{ marginTop: "12pt" }}
                    inputMode="numeric"
                    id="box-size-width"
                    label="Width"
                    variant="outlined"
                    value={dialogData.boxWidth || ""}
                    onChange={({ target }) => {
                      try {
                        if (target.value === "") {
                          setDialogData({
                            ...dialogData,
                            boxWidth: undefined,
                          });
                          return;
                        }
                        const val = Number.parseInt(target.value);
                        if (Number.isFinite(val))
                          setDialogData({
                            ...dialogData,
                            boxWidth: val,
                          });
                      } catch (err) {
                        console.warn(err);
                      }
                    }}
                  />
                  <TextField
                    sx={{ marginTop: "12pt" }}
                    inputMode="numeric"
                    id="box-size-height"
                    label="Height"
                    variant="outlined"
                    value={dialogData.boxHeight || ""}
                    onChange={({ target }) => {
                      try {
                        if (target.value === "") {
                          setDialogData({
                            ...dialogData,
                            boxHeight: undefined,
                          });
                          return;
                        }
                        const val = Number.parseInt(target.value);
                        if (Number.isFinite(val))
                          setDialogData({
                            ...dialogData,
                            boxHeight: val,
                          });
                      } catch (err) {
                        console.warn(err);
                      }
                    }}
                  />
                  <FormControl sx={{ width: "100pt", marginTop: "12pt" }}>
                    <InputLabel id="select-format-label">Format</InputLabel>
                    <Select
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      labelId="select-format-label"
                      id="select-format-select"
                      label={"Format"}
                      value={
                        Object.keys(viewPorts).find(
                          (e) =>
                            viewPorts[e as keyof typeof viewPorts].width ===
                              dialogData.boxWidth &&
                            viewPorts[e as keyof typeof viewPorts].height ===
                              dialogData.boxHeight
                        ) || ""
                      }
                      onChange={(e) => {
                        if (e.target.value) {
                          const viewport =
                            viewPorts[e.target.value as keyof typeof viewPorts];
                          if (viewport) {
                            setDialogData({
                              ...dialogData,
                              boxWidth: viewport.width,
                              boxHeight: viewport.height,
                            });
                          }
                        }
                      }}
                    >
                      {Object.keys(viewPorts).map((k) => (
                        <MenuItem
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                          key={`menu_item_viewports_${k}`}
                          value={k}
                        >
                          {k.toUpperCase().replace(/-/gi, " ")}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={"12pt"} paddingRight={"12pt"}>
                  <Paper variant="outlined">
                    <InputLabel
                      sx={{
                        marginX: "3pt",
                        marginY: "-9pt",
                        backgroundColor: "white",
                        paddingLeft: "3pt",
                        paddingRight: "3pt",
                        width: "68pt",
                      }}
                    >
                      Arguments
                    </InputLabel>
                    <AceEditor
                      placeholder="{}"
                      mode="json"
                      theme="github"
                      onChange={(val) => {
                        try {
                          setDialogData({
                            ...dialogData,
                            templateArgs: val,
                          });
                        } catch (err) {
                          console.warn(err);
                        }
                      }}
                      value={dialogData.templateArgs}
                      name="editor-template-arguments"
                      setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: false,
                        showLineNumbers: false,
                        tabSize: 2,
                        useWorker: false,
                      }}
                      editorProps={{ $blockScrolling: true }}
                      fontSize={14}
                      showPrintMargin={true}
                      showGutter={false}
                      highlightActiveLine={true}
                      style={{ marginTop: "12pt" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5}>
                <InputLabel>Preview</InputLabel>
                <iframe
                  id="template-frame-2"
                  title="Template"
                  style={{
                    width: dialogData.boxWidth,
                    height: dialogData.boxHeight,
                    zoom: 0.75,
                    transform: "scale(0.75)",
                    transformOrigin: "0 0",
                    overflow: "scroll",
                  }}
                  srcDoc={safeMustacheRender(
                    templateData.template,
                    isValidJson(dialogData.templateArgs || "") || {}
                  )}
                ></iframe>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setTemplateData({
                  ...templateData,
                  name: dialogData.templateName ?? templateData.name,
                  box: {
                    ...templateData.box,
                    width: dialogData.boxWidth ?? templateData.box.width,
                    height: dialogData.boxHeight ?? templateData.box.height,
                  },
                  args:
                    dialogData.templateArgs &&
                    isValidJson(dialogData.templateArgs)
                      ? dialogData.templateArgs
                      : templateData.args,
                });
                setOpenDialog(false);
              }}
              disabled={false}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {showConfirmDialog.isShown ? (
        <Dialog open={true}>
          <DialogContent>
            {showConfirmDialog.type === "delete" ? (
              <Typography>
                Are you sure you want to delete template
                <br />
                <b>
                  {templateData.name}(
                  {templateId.replace(/^(private_|public_)/, "")})
                </b>
                ?
              </Typography>
            ) : (
              <Typography>
                Are you sure you want to change the template
                <br />
                <b>
                  {templateData.name}(
                  {templateId.replace(/^(private_|public_)/, "")})
                </b>
                ?
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowConfirmDialog({
                  ...showConfirmDialog,
                  isShown: false,
                });
              }}
              disabled={isRunOperation}
            >
              No
            </Button>
            <Button
              onClick={async () => {
                try {
                  setRunOperation(true);
                  const originTemplateId = templateId.replace(
                    /^(private_|public_)/,
                    ""
                  );
                  if (isPrivateTemplate) {
                    if (showConfirmDialog.type === "upsert") {
                      logEvent(FirebaseAnalytics, "upsert_private_template");
                      await upsertTemplate[0]({
                        id: originTemplateId,
                        name: templateData.name,
                        html: templateData.template,
                        arguments: isValidJson(templateData.args) || {},
                        width: templateData.box.width,
                        height: templateData.box.height,
                      });
                      if (upsertTemplate[2]) {
                        throw getErrorFromCallable(upsertTemplate[2]);
                      } else {
                        setShowMsg({
                          type: "success",
                          message: "Updated!",
                          isShown: true,
                        });
                      }
                    } else if (showConfirmDialog.type === "delete") {
                      logEvent(FirebaseAnalytics, "remove_private_template");
                      await deleteTemplate[0]({
                        id: originTemplateId,
                      });
                      if (deleteTemplate[2]) {
                        throw getErrorFromCallable(deleteTemplate[2]);
                      }
                      setShowMsg({
                        type: "success",
                        message: "Deleted!",
                        isShown: true,
                      });
                    }
                  } else {
                    if (showConfirmDialog.type === "upsert") {
                      logEvent(FirebaseAnalytics, "upsert_public_template");
                      await upsertPublicTemplate[0]({
                        id: originTemplateId,
                        name: templateData.name,
                        html: templateData.template,
                        arguments: isValidJson(templateData.args) || {},
                        width: templateData.box.width,
                        height: templateData.box.height,
                      });
                      if (upsertPublicTemplate[2]) {
                        throw getErrorFromCallable(upsertPublicTemplate[2]);
                      } else {
                        setShowMsg({
                          type: "success",
                          message: "Updated!",
                          isShown: true,
                        });
                      }
                    } else if (showConfirmDialog.type === "delete") {
                      logEvent(FirebaseAnalytics, "remove_public_template");
                      await deletePublicTemplate[0]({
                        id: originTemplateId,
                      });
                      if (deletePublicTemplate[2]) {
                        throw getErrorFromCallable(deletePublicTemplate[2]);
                      }
                      setShowMsg({
                        type: "success",
                        message: "Deleted!",
                        isShown: true,
                      });
                    }
                  }
                  reloadTemplates();
                  setShowConfirmDialog({
                    ...showConfirmDialog,
                    isShown: false,
                  });
                } catch (err: Error | any) {
                  setShowMsg({
                    type: "error",
                    message: err.message || "Unknown error",
                    isShown: true,
                  });
                } finally {
                  setRunOperation(false);
                }
              }}
              disabled={isRunOperation}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Snackbar
        open={showMsg.isShown}
        autoHideDuration={6000}
        onClose={() => {
          setShowMsg({ ...showMsg, isShown: false });
        }}
        sx={{ width: "100%", padding: "3vmin", justifyContent: "end" }}
      >
        {
          <Alert
            onClose={() => {
              setShowMsg({ ...showMsg, isShown: false });
            }}
            sx={{ marginRight: "3vmin" }}
            severity={showMsg.type}
          >
            {showMsg.message}
          </Alert>
        }
      </Snackbar>
    </div>
  ) : (
    <Loader />
  );
}

export default TemplatesRoute;
